<template>
  <div class="view not-found">
    <navbar/>
    <main class="flex-container">
      <div class="content center">
        <font-awesome-icon icon="exclamation-triangle" class="warning-icon" />
        <h1>Not Found</h1>
        <p class="lead">The page you are looking for doesn't exist. How odd.</p>
        <p>Try heading back to the <router-link to="/">home page</router-link>.</p>
      </div>
    </main>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'

export default {
  name: 'NotFound',
  components: { Navbar },
}
</script>

<style lang="scss" scoped>
.not-found {
  .warning-icon {
    font-size: 12em;
    margin-block-end: 2rem;
    color: var(--c-txt-secondary);
  }
}
</style>
